import { computed, Injectable, signal } from '@angular/core';
import { isEqual } from 'lodash-es';
import { CrmStringDictionary } from 'common-module/core/types';

import { ClinicModel } from '~/api/clinics/clinic.model';

import { CurrentClinic } from '../types/current-clinic';

@Injectable({ providedIn: 'root' })
export class CurrentClinicService {
  private readonly currentClinic = signal<CurrentClinic | undefined>(undefined);

  readonly clinic = computed(
    () => {
      const clinic = this.currentClinic();

      if (!clinic) {
        throw new Error('Clinic not found.');
      }

      return clinic;
    },
    { equal: isEqual },
  );

  readonly billingTemplate = computed(() => {
    try {
      return this.clinic().billingTemplate;
    } catch (_) {
      return 'sk.unrounded';
    }
  });

  readonly locale = computed(() => {
    try {
      return this.clinic().locale;
    } catch (_) {
      return 'sk';
    }
  });

  readonly currencySymbol = computed(() => {
    try {
      return this.clinic().currency.symbol;
    } catch (_) {
      return '€';
    }
  });

  readonly phonePrefix = computed(() => {
    try {
      return this.clinic().phonePrefix;
    } catch (_) {
      return '+421';
    }
  });

  setCurrentClinic(model: ClinicModel) {
    this.currentClinic.set({
      id: model._id,
      name: model.name,
      locale: model.meta.locale ?? 'sk',
      currency: this.getCurrency(model.meta.currency ?? 'EUR'),
      phonePrefix: model.meta.phonePrefix ?? '+421',
      billingTemplate: model.meta.billing?.template ?? 'sk.unrounded',
      logo: model.meta.logo,
      model,
    });
  }

  invalidate() {
    this.currentClinic.set(undefined);
  }

  getPrioritizedCountries() {
    const location = this.locale().toUpperCase();
    const priority = ['SK', 'CZ', 'AT', 'HU', 'PL', 'UA'];
    const filtered = priority.filter((country) => country !== location);
    return [location, ...filtered];
  }

  private getCurrency(currency: string) {
    const symbol: CrmStringDictionary = {
      EUR: '€',
      CZK: 'Kč',
    };

    return { code: currency, symbol: symbol[currency] ?? currency };
  }
}
